.container.testimonials__container {
  width: 40%;
  padding-bottom: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.client__avatar {
  width: 20rem;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 1rem;
  object-fit: fill;
  display: flex; /* Ensures the container is properly aligned */
  justify-content: center;
  align-items: center;
  border: 0.4rem solid var(--color-primary-variant);
}
.client__avatar img {
  width: 100%; /* Makes the image fill the width of the container */
  height: 100%; /* Makes the image fill the height of the container */
  object-fit: fill; /* Ensures the image covers the space without losing aspect ratio */
  border-radius: 50%; /* Optional: if you want the image itself to be rounded */
}

.testimonial {
  background: var(--color-bg-variant);
  text-align: center;
  padding: 2rem;
  border-radius: 2rem;
  user-select: none;
}

.client__review {
  color: var(--color-light);
  font-weight: 300;
  display: block;
  width: 80%;
  margin: 0.8rem auto 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  background: var(--color-primary);
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  .container.testimonials__container {
    width: 60%;
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  .container.testimonials__container {
    width: var(--container-width-sm);
  }

  .client__review {
    width: var(--container-width-sm);
  }
}
